import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { RefObject, useRef } from "react";
import { Kbd } from "admin/components/common/Kbd";
import { PopoverAnimated } from "admin/components/common/PopoverAnimated";
import {
  ShortcutPathIdentifier,
  useShortcutPathMap,
} from "./useShortcutPathMap";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { useShortcutExists } from "admin/lib/useShortcut";
import { useAppContext } from "made-this-ui";

interface ShortcutsButtonProps {
  handleNewShortcutClick: () => void;
  handleViewShortcutsClick: () => void;
  viewButtonRef?: RefObject<HTMLButtonElement>;
  newButtonRef?: RefObject<HTMLButtonElement>;
}

export const ShortcutsButton = ({
  viewButtonRef,
  newButtonRef,
  handleNewShortcutClick,
  handleViewShortcutsClick,
}: ShortcutsButtonProps) => {
  const {
    icons: { IconShortcut, IconKeyCommand, IconKeyG, IconKeyE },
  } = useTheme();
  const { pathIdentifier } = useShortcutPathMap();
  const { model } = useAdminTranslation();
  const { t } = useAdminTranslation();
  const { isCollapsed } = useAppContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shortcutListDisclosure = useDisclosure();
  const isExisting = useShortcutExists();

  return (
    <Box
      pos="relative"
      ref={containerRef}
      width={isCollapsed ? "unset" : "100%"}
    >
      <ButtonGroup
        size="md"
        isAttached
        variant="outline"
        borderColor={"rgba(255, 255, 255, 0.36)"}
        display="flex"
      >
        <Button
          ref={viewButtonRef}
          _hover={{ bg: "primary.400" }}
          onClick={handleViewShortcutsClick}
          onMouseEnter={shortcutListDisclosure.onOpen}
          onMouseLeave={shortcutListDisclosure.onClose}
          color="white"
          p={0}
          width={isCollapsed ? "42px" : "100%"}
          height={"38px"}
          display="flex"
          justifyContent="flex-start"
        >
          <IconShortcut fontSize={20} color="white" mx={2} />
          {!isCollapsed && model("shortcut", { count: 2 })}
        </Button>
        {!isCollapsed && (
          <IconButton
            ref={newButtonRef}
            _hover={{ bg: "gray.100" }}
            aria-label={t("texts.shortcuts.aria_label_create_shortcut")}
            icon={<AddIcon fontSize={9} color="white" />}
            onClick={handleNewShortcutClick}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            isDisabled={pathIdentifier === ShortcutPathIdentifier.None}
            height={"38px"}
          />
        )}
      </ButtonGroup>

      <PopoverAnimated
        isOpen={!isCollapsed && shortcutListDisclosure.isOpen}
        onClose={shortcutListDisclosure.onClose}
        portalContainerRef={containerRef}
        top="48px"
        left={0}
        rounded="sm"
        shadow="md"
        p={1}
      >
        <Kbd
          label={t("texts.shortcuts.list_existing_shortcuts")}
          textProps={{
            whiteSpace: "nowrap",
          }}
        >
          <IconKeyCommand color="gray.500" /> + <IconKeyE color="gray.500" />
        </Kbd>
      </PopoverAnimated>

      <PopoverAnimated
        portalContainerRef={containerRef}
        isOpen={isOpen}
        onClose={onClose}
        top="48px"
        right={0}
        rounded="sm"
        shadow="md"
        p={1}
        whiteSpace={"nowrap"}
      >
        <Kbd
          label={
            isExisting
              ? t("texts.shortcuts.update_existing_kbd_shortcut")
              : t("texts.shortcuts.create_new_kbd_shortcut")
          }
        >
          <IconKeyCommand color="gray.500" /> + <IconKeyG color="gray.500" />
        </Kbd>
      </PopoverAnimated>
    </Box>
  );
};
