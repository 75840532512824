import {
  Box,
  Center,
  ComponentWithAs,
  IconButton,
  IconProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { KbdNavigation } from "admin/components/common/KbdNavigation";
import { useAppContext } from "made-this-ui";

export interface FooterMenuProps {
  icon: ComponentWithAs<"svg", IconProps>;
  notificationCount?: number;
  content: ReactNode;
}

export const FooterMenu = ({
  icon: Icon,
  content,
  notificationCount = 0,
}: FooterMenuProps) => {
  const { isCollapsed } = useAppContext();

  return (
    <Popover
      isOpen={notificationCount === 0 ? false : undefined}
      placement="bottom-end"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Box
          position="relative"
          backgroundColor={"primary.100"}
          borderRadius={8}
          px={isCollapsed ? 1 : 6}
          py={1}
        >
          <IconButton
            aria-label={"action"}
            variant="ghost"
            background="twitter.200"
            disabled={!notificationCount}
            icon={<Icon fontSize={24} />}
            _hover={{ bg: "twitter.200" }}
          />
          {!!notificationCount && (
            <Center
              rounded="sm"
              color="white"
              bg="warning.500"
              fontSize="10.5px"
              border="1px solid"
              borderColor="primary.50"
              cursor="pointer"
              pos="absolute"
              top={17}
              right={22}
              w="18px"
              h="18px"
              borderRadius="50%"
            >
              {notificationCount}
            </Center>
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w={"500px"}>
          <PopoverBody p={6} bg="white">
            {content}
            <KbdNavigation mt={4} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
