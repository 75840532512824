import { InputSearch } from "admin/components/inputs/InputSearch";
import { Box, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

export interface GlobalSearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const GlobalSearchInput = ({
  value,
  onChange,
}: GlobalSearchInputProps) => {
  return (
    <Box bg="gray.50" p={4} borderBottom="1px solid" borderColor="gray.100">
      <InputSearch
        size="lg"
        inputProps={{
          value,
          autoFocus: true,
          onChange: ({ target }) => onChange(target.value),
        }}
        rightElement={
          value && (
            <IconButton
              variant="unstyled"
              aria-label="clear search"
              icon={<CloseIcon fontSize={12} color="gray.800" />}
              onClick={() => onChange("")}
            />
          )
        }
      />
    </Box>
  );
};
