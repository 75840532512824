import { useRouter } from "next/router";
import { getFragmentData, graphql } from "admin/graphql/generated";
import { useGraphQLQuery } from "made-this-ui";
import { useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useAdminTranslation } from "./useAdminTranslation";

const ShortcutFragment = graphql(/* GraphQL */ `
  fragment ShortcutFragment on Shortcut {
    id
    type
    name
    state
  }
`);

const ShortcutQuery = graphql(/* GraphQL */ `
  query Shortcut($shortcutId: ID!) {
    currentUser {
      shortcut(shortcutId: $shortcutId) {
        ...ShortcutFragment
      }
    }
  }
`);

const useShortcutQuery = (
  shortcutId: string,
  enabled: boolean,
  onSuccess?: (data: any) => void
) => {
  const { replace, pathname, query } = useRouter();
  const toast = useToast();
  const { model, t } = useAdminTranslation();

  return useGraphQLQuery(ShortcutQuery, {
    variables: {
      shortcutId,
    },
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess:
      onSuccess !== undefined
        ? (data) => {
            const shortcut = getFragmentData(
              ShortcutFragment,
              data!.currentUser?.shortcut
            );

            return onSuccess(shortcut!.state);
          }
        : undefined,
    onError: (err) => {
      const newQuery = {
        ...query,
      };
      delete newQuery.sid;
      toast({
        position: "top",
        status: "error",
        title: `${model("shortcut")} ${t(
          "rails:models.errors.messages.not_found"
        )}`,
      });
      replace(
        {
          pathname: pathname,
          query: {
            ...newQuery,
          },
        },
        undefined,
        { shallow: true }
      );
    },
  });
};

export type UseShortcutProps<TData = any> = {
  onSuccess?: (data?: TData) => void;
};

export const useShortcutExists = () => {
  const { query } = useRouter();

  return useMemo(
    () =>
      query.sid !== undefined &&
      typeof query.sid === "string" &&
      query.sid.length > 0,
    [query]
  );
};

export const useShortcut = <TData = any>(props?: UseShortcutProps<TData>) => {
  const { query } = useRouter();
  const exists = useShortcutExists();
  const { data, isLoading } = useShortcutQuery(
    query.sid as string,
    exists,
    props?.onSuccess
  );

  if (!exists) {
    return {
      isLoading: false,
      data: null,
      exists,
    };
  }

  const shortcut = getFragmentData(
    ShortcutFragment,
    data?.currentUser?.shortcut
  );

  return {
    isLoading: exists ? isLoading : false,
    data: shortcut ? shortcut : undefined,
    exists,
  };
};
