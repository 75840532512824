import { HStack, useTheme } from "@chakra-ui/react";
import { withFooterActionsDataProvider } from "./FooterActionsDataProvider";
import {
  FooterPopoverInbox,
  FooterMenu,
  FooterPopoverMessages,
  FooterPopoverFlagged,
} from ".";
import { useAppContext } from "made-this-ui";

export const FooterActions = withFooterActionsDataProvider(({ actionData }) => {
  const messagesCount = actionData.messages.records.length;
  const flaggedCount = actionData.flagged.records.length;
  const inboxCount = actionData.inbox.records.length;
  const {
    icons: {
      IconMessagesHighlighted,
      IconMessages,
      IconFlagHighlighted,
      IconFlag,
      IconInboxHighlighted,
      IconInbox,
    },
  } = useTheme();
  const { isCollapsed } = useAppContext();
  return (
    <>
      <HStack
        spacing={isCollapsed ? 0 : 2}
        display="flex"
        flexDirection={isCollapsed ? "column" : "row"}
        alignItems="center"
        justifyContent={isCollapsed ? "space-around" : "center"}
        height={isCollapsed ? 148 : "unset"}
      >
        <FooterMenu
          notificationCount={messagesCount}
          content={
            <FooterPopoverMessages records={actionData.messages.records} />
          }
          icon={messagesCount ? IconMessagesHighlighted : IconMessages}
        />
        <FooterMenu
          notificationCount={flaggedCount}
          content={
            <FooterPopoverFlagged records={actionData.flagged.records} />
          }
          icon={flaggedCount ? IconFlagHighlighted : IconFlag}
        />
        <FooterMenu
          notificationCount={inboxCount}
          content={<FooterPopoverInbox records={actionData.inbox.records} />}
          icon={inboxCount ? IconInboxHighlighted : IconInbox}
        />
      </HStack>
    </>
  );
});
