import {
  Box,
  Flex,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import { Kbd } from "admin/components/common/Kbd";
import { InputSearch } from "admin/components/inputs/InputSearch";
import { useKeyPress } from "admin/lib/useKeyPress";
import { useAppContext } from "made-this-ui";
import { GlobalSearchModal } from "./GlobalSearchModal";

const getGetOS = () => {
  if (typeof navigator === "undefined") return;
  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  return isMac ? "macos" : "other";
};

export const GlobalSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isCollapsed } = useAppContext();

  const {
    icons: { IconSearch, IconSearchHighlighted },
  } = useTheme();

  const handleModalClose = () => {
    onClose();
  };

  useKeyPress(
    "k",
    () => {
      onOpen();
    },
    true
  );

  const cmdOrCtrl = getGetOS() ? "⌘" : "ctrl";

  return (
    <>
      <Box mr={isCollapsed ? "0" : "3"} mb={isCollapsed ? "3" : "0"}>
        <IconButton
          aria-label="search"
          icon={<IconSearchHighlighted fontSize={20} color="white" />}
          onClick={onOpen}
          variant="outline"
          width={"42px"}
          height={"38px"}
          _hover={{ bg: "primary.400" }}
        />
      </Box>
      {isOpen && <GlobalSearchModal onClose={handleModalClose} />}
    </>
  );
};
