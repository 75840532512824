import {
  Avatar,
  Center,
  ComponentWithAs,
  Flex,
  FlexProps,
  IconProps,
  Switch,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { CurrentUserFragmentType } from "admin/lib/useCurrentUser";
import {
  AppNavigation,
  AppNavigationBody,
  AppNavigationFooter,
  AppNavigationHeader,
  AppNavigationItem,
  useAppContext,
} from "made-this-ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { FooterActions } from "./FooterActions";
import { GlobalSearch } from "./GlobalSearch";
import { Shortcuts } from "./Shortcuts";

const getSidebarMenu = (icons: {
  [key: string]: ComponentWithAs<"svg", IconProps>;
}) => [
  {
    id: 0,
    href: "/next/admin/cases",
    title: "case",
    icon: icons.IconCases,
  },
  {
    id: 1,
    href: "/next/admin/tenancies",
    title: "tenancy",
    icon: icons.IconTenant,
  },
  {
    id: 2,
    href: "/next/admin/apartments",
    title: "apartment",
    icon: icons.IconApartment,
  },
  {
    id: 3,
    href: "/next/admin/properties",
    title: "property",
    icon: icons.IconProperty,
  },
];

interface NavigationProps extends FlexProps {
  user: CurrentUserFragmentType;
}

export const Navigation = ({ user }: NavigationProps) => {
  const {
    icons: { IconCases, IconTenant, IconApartment, IconProperty, IconLogoFull },
  } = useTheme();

  const { isCollapsed } = useAppContext();

  const { model } = useAdminTranslation();

  const router = useRouter();
  const isPath = (path: string) => router.pathname.startsWith(path);

  const sidebarMenu = useMemo(() => {
    return getSidebarMenu({
      IconCases,
      IconTenant,
      IconApartment,
      IconProperty,
    });
  }, [IconProperty, IconCases, IconTenant, IconApartment]);

  return (
    <AppNavigation backgroundColor={"primary.500"}>
      <AppNavigationHeader
        title={user.name}
        avatar={
          <Avatar
            name={user.name}
            backgroundColor="gray.800"
            color="white"
            w={10}
            h={10}
          />
        }
      >
        <Flex
          px={isCollapsed ? 0 : 3}
          pb={6}
          flexDirection={isCollapsed ? "column" : "row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <GlobalSearch />
          <Shortcuts />
        </Flex>
      </AppNavigationHeader>
      <AppNavigationBody>
        {sidebarMenu.map(({ id, href, title, icon }) => {
          return (
            <AppNavigationItem
              key={id}
              label={model(title, { count: 2 })}
              isActive={isPath(href)}
              href={href}
              icon={icon}
            />
          );
        })}
      </AppNavigationBody>
      <AppNavigationFooter
        logo={<IconLogoFull color="gray.800" h={30} w={"auto"} />}
      >
        <FooterActions />

        <Flex mt={8} mx={3} flexDirection={isCollapsed ? "column" : "row"}>
          <Center w={isCollapsed ? "100%" : 8}>
            <Switch
              defaultChecked
              onChange={() => {
                const host = window.location.host;
                window.location.href = `http://${host}/admin`;
              }}
              borderRadius={99}
              size="sm"
              outline={"1px solid white"}
            />
          </Center>

          <Text
            mt={isCollapsed ? 2 : 0}
            ml={isCollapsed ? 0 : 2}
            color="whiteAlpha.700"
          >
            Nyt design
          </Text>
        </Flex>
      </AppNavigationFooter>
    </AppNavigation>
  );
};
